


































































  import { Validator } from '@/constant/Mixins';
  import { ServerResponse } from '@/services/response.types';
  import { CityModel, ProvinceModel } from '@/store/location/types';
  import { ProductBrandModel } from '@/store/product/types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'buyMobil',
    components: {},
    mixins: [],
  })
  export default class buyMobil extends Mixins(Validator) {
    @Action('BuySubmitDetailProduct', { namespace: 'product/motor' }) BuySubmitDetailProduct: ({form: FormData, quotation_id: string}) => Promise<ServerResponse>;
    @Action('GetBrands', { namespace: 'product/motor' }) GetBrands: (quotation_id: string) => Promise<ServerResponse>;
    @Action('GetProvince', { namespace: 'location' }) GetProvince!: (params?: any) => Promise<ServerResponse>;
    @Action('GetCity', { namespace: 'location' }) GetCity!: (params?: any) => Promise<ServerResponse>;

    @State('brands', { namespace: 'product/motor' }) brands!: ProductBrandModel[];
    @State('province', { namespace: 'location' }) province!: ProvinceModel[];
    @State('city', { namespace: 'location' }) city!: CityModel[];

    public quotation_id: any = null;

    public formDetail = {
      brand_id: 0,
      model: '',
      frame_number: '',
      machine_number: '',
      plate_number: '',
      warna: '',
      state_id: 0,
      city_id: 0,
    };

    public get brandOptions(): { text: string; value: string }[] {
      return this.brands.map((item) => {
        return {
          text: item.brand_name,
          value: item.brand_id,
        };
      });
    }

    public get provinceOptions(): { text: string; value: number }[] {
      return this.province.map((item) => {
        return {
          text: item.state_name,
          value: Number(item.state_id),
        };
      });
    }

    public get cityOptions(): { text: string; value: string, state_id:number }[] {
      return this.city.map((item) => {
        return {
          text: item.city_name,
          value: item.city_id,
          state_id: Number(item.state_id)
        };
      }).filter((item) => item.state_id === this.formDetail.state_id);
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      this.quotation_id = this.$route.query.q;
      if (this.quotation_id) {
        this.GetBrands(this.quotation_id);
        this.GetProvince();
      }
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
       this.BuySubmitDetailProduct({
         form: new FormData(ev?.target as HTMLFormElement),
         quotation_id: this.quotation_id
       }).then((res) => {
         if(res.status.is_valid) this.$router.push({ name: 'info-item-motor', query: this.$route.query })
       })
    }
  }
